@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Whisper&family=Abel&family=Anek+Telugu:wght@200&family=Quicksand:wght@300&family=Comfortaa:wght@300&family=Roboto&family=Cinzel:wght@500&family=Josefin+Sans:wght@500&display=swap');

:root {
  --first_col: #8d99ae;
  --second_col: #edf2f4;
  --third_col: #ef233c;
  --fourth_col: #d90429;
  --fifth_col: #32054a;
}
.body{
  margin:0;
  padding:0;
  box-sizing: border-box;
}

.full-container{
  overflow-y:auto;
  min-height:750px;
/* ::-webkit-scrollbar {display:none;} */

}

.video{
  width: 100vw !important;
  min-height: 80vh !important;
}
.navbar{
  background: var(--second_col);
}
.navigation-container{
  position: fixed;
  width: 100vw;
  z-index: 999;
}
.nav-item a{
  color:var(--fifth_col);
  text-decoration: none;
  padding:6px 25px;
  transition: all 0.6s ease-in;
  font-size: 0.95rem;
  border-radius: 10px;
  font-weight: 500;
}

.nav-item a:hover{
  color:var(--second_col);
  background:var(--first_col);
  
}


.my-50{
  margin-top:50px;
  margin-bottom: 50px;
}

.my-100{
  margin-top:100px;
  margin-bottom: 100px;
}


.mx-50{
  margin-left:50px;
  margin-right: 50px;
}

.minh-img{
  min-height: 300px;
}

.h-500{
  height:500px;
}

.navbar-expand-lg{
  flex-wrap: nowrap;
}

.contact-svg{
  width: 120px;
  height: auto;
}

.contact-msg{
  font-family: 'Comfortaa', cursive;
}

.bg-dark{
  background-color: #32054a !important;
}

.star-col{
  color:#32054a;
}

.star-title{
  color:#32054a;
  font-family: 'Comfortaa', cursive;
}

.text-dark{
  color:#32054a !important;
}

.skcard:hover{
  background-color: #32054a !important;
  color:white !important
}

.skillheight{
  min-height: 80vh;
}

/*====== Form input style ====== */

.input-container{
	position:relative;
	margin-bottom:25px;
}

.input-container label{
	position:absolute;
	top:0px;
	left:0px;
	font-size:16px;
	transition: all 0.5s ease-in-out;
}

.input-container input{ 
  border:0;
  border-bottom:dotted 2px rgb(142, 142, 142); 
  background:transparent;
  width:100%;
  padding:8px 0 5px 0;
  font-size:16px;
  color:#32054a;
}

.input-container input:focus{ 
 border:none;	
 outline:none;
 border-bottom:1px solid #e74c3c;	
}

.input-container input:focus ~ label,
.input-container input:valid ~ label{
	top:-12px;
	font-size:14px;
	
}

textarea{
  background: #fff;
  border:dotted 2px rgb(142, 142, 142);
}
textarea:focus-visible {
  outline: red 0.75px solid !important;
}

.img-contact{
  opacity:0.5;
}

h4 a{
  color:#32054a;
}

h4 a:hover{
  color:#555;
}

.contact-panel-right{
  display: none;
  min-width: 365px;
  width: 30vw;
  height: 620px;
  background-color: var(--third_col);
}
@media screen and (min-width:1400px){
  .contact-panel-right{
    display: block;
  }
}


.contact-cont{
  background: var(--second_col);
}

.carousel-control-next-icon,.carousel-control-prev-icon{
  background: black;
  opacity: 0.7;
  border-radius: 50%;
  color: white;
  height: 35px;
  transition: all 0.s ease;
}

.carousel-control-next-icon:hover,.carousel-control-prev-icon:hover{
  background: white;
  color: black;
}

.carousel-control-prev-icon::after{
  content:"\2039";
  font-size:24px;
  font-weight: 600;
}

.carousel-control-next-icon::after{
  content:"\203A";
  font-size:24px;
  font-weight: 600;
}